import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IntercomProvider } from 'react-use-intercom';
import { init, track } from '@amplitude/analytics-browser';
import './App.scss';

import InfoPage from './pages/info';
import Spinner from './components/spinner';
import { completeReq, getDocPdf, getPaymentUrl, getReqInfo, ReqStatuses } from './api/apiService';
import NotFound from './pages/not-fornd';
import PaymentError from './pages/failed';
import SuccessPayment from './pages/success';
import Payment from './pages/payment';
import fileDownload from 'js-file-download';
import InactivePage from './pages/inactive';
import ExpiredPage from './pages/expired';
import ProcessingPage from './pages/processing';

enum Pages {
  INFO = 'INFO',
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  PAYMENT = 'PAYMENT',
  PROCESSING = 'PROCESSING',
  PAYMENT_SUCCESS = 'PAYMENT_SUCCESS',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
}

function App() {
  const INTERCOM_APP_ID = 'oe159t1v';
  const { i18n } = useTranslation();
  const [dir, setDir] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [page, setPage] = useState<Pages>();
  const [reqId, setReqId] = useState<any>(null);
  const [request, setRequest] = useState<any>(null);
  const [url, setUrl] = useState<any>(null);

  const getPaymentInfo = useCallback(async (reqId) => {
    setSpinner(true);

    getReqInfo(reqId)
      .then((req) => {
        if (req.status !== 'SUCCESS') {
          throw new Error('Failed to get money request.');
        }

        if (req.requestMoneyStatus === ReqStatuses.PROCESSING) {
          setPage(Pages.PROCESSING);
          return;
        }

        if (req.requestMoneyStatus === ReqStatuses.COMPLETED) {
          setPage(Pages.PAYMENT_SUCCESS);
          return;
        }

        if (req.requestMoneyStatus === ReqStatuses.EXPIRED) {
          setPage(Pages.EXPIRED);
          return;
        }

        if (req.requestMoneyStatus === ReqStatuses.INACTIVE) {
          setPage(Pages.INACTIVE);
          return;
        }

        setRequest(req);
        setPage(Pages.INFO);
        track('Request money - Web welcome page', {
          amount: req.amount,
          receivername: req.requester,
        });
      })
      .catch((error) => {
        setPage(Pages.NOT_FOUND);
        console.error(error);
      })
      .finally(() => {
        setSpinner(false);
      });
  }, []);

  const handlePay = useCallback(() => {
    track('Request money - Web welcome page - Pay button', {
      amount: request.amount,
      receivername: request.requester,
    });

    setSpinner(true);

    const { organizationId } = request;

    getPaymentUrl({
      organizationId,
      requestMoneyEncodedId: reqId,
    })
      .then(({ pageUrl, status, message }) => {
        if (status !== 'SUCCESS') {
          throw new Error(message || 'Transaction failed.');
        }

        setUrl(pageUrl);
        setPage(Pages.PAYMENT);
        track('Request money - Web Page - Payment page', {
          amount: request.amount,
          receivername: request.requester,
        });
      })
      .catch((error) => {
        setPage(Pages.PAYMENT_ERROR);
        console.error(error);
      })
      .finally(() => {
        setSpinner(false);
      });
  }, [request]);

  const handleSuccsessPayment = useCallback(
    ({ transactionIdExternal }) => {
      setSpinner(true);

      const payload = {
        encodedId: reqId,
        transactionIdExternal,
        requestPaymentStatus: 'COMPLETED',
      };

      completeReq(payload)
        .then(({ status, message }) => {
          if (status !== 'SUCCESS') {
            throw new Error(message || 'Transaction failed.');
          }

          setPage(Pages.PAYMENT_SUCCESS);
          track('Request money - Web page - Request paid', {
            amount: request.amount,
            receivername: request.requester,
          });
        })
        .catch((error) => {
          setPage(Pages.PAYMENT_ERROR);
          console.error(error);
        })
        .finally(() => {
          setSpinner(false);
        });
    },
    [reqId, request]
  );

  const handlePaymentError = useCallback(({ error }) => {
    setPage(Pages.PAYMENT_ERROR);
  }, []);

  const handleRety = () => {
    setPage(Pages.INFO);
  };

  const downloadReceipt = useCallback(
    async ({ reqId }) => {
      const receipt: any = await getDocPdf(reqId);
      fileDownload(receipt, `${reqId}.pdf`);
      track('Request money - Web page - Save Receipt', {
        amount: request.amount,
        receivername: request.requester,
      });
    },
    [request]
  );

  useEffect(() => {
    const lang = i18n.language.split('-')[0];
    setDir(lang === 'il' ? 'rtl' : '');

    init(process.env.REACT_APP_AMPLITUDE_API_KEY || '');

    const reqId = window.location.pathname.split('/')[1];
    if (reqId) {
      setReqId(reqId);
      getPaymentInfo(reqId);
    } else {
      setPage(Pages.NOT_FOUND);
    }
  }, [i18n, getPaymentInfo]);

  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <div dir={dir}>
        {spinner && <Spinner fullPage={true}></Spinner>}

        {page === Pages.INFO && <InfoPage request={request} handlePay={handlePay} />}
        {page === Pages.NOT_FOUND && <NotFound />}
        {page === Pages.PAYMENT && (
          <Payment
            url={url}
            handlePayment={handleSuccsessPayment}
            handleError={handlePaymentError}
          />
        )}
        {page === Pages.PROCESSING && <ProcessingPage />}
        {page === Pages.PAYMENT_SUCCESS && (
          <SuccessPayment handleDownload={() => downloadReceipt(reqId)} />
        )}
        {page === Pages.INACTIVE && <InactivePage />}
        {page === Pages.EXPIRED && <ExpiredPage />}
        {page === Pages.PAYMENT_ERROR && <PaymentError retry={handleRety} />}
      </div>
    </IntercomProvider>
  );
}

export default App;
