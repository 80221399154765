import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './info.module.scss';
import Footer from '../../components/footer';
import Button from '../../components/button';
import Header from '../../components/header';
import logo from '../../logo.svg';
import { useIntercom } from 'react-use-intercom';

const InfoPage = ({ request, handlePay }: any) => {
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const intercomSettings = {
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  const currencySigns = {
    ILS: '₪',
    USD: '$',
    EUR: '€',
  };

  return (
    <div className={styles.infoPage}>
      <Header></Header>

      <div className={styles.content}>
        <div className={styles.platformInfo}>
          <div>
            <strong>EasyPay ©</strong>
            <div>{t('fast_payment_solutions')}</div>
          </div>
        </div>

        <div>
          <div>
            <div className={styles.requestedBy}>
              {t('you_received_a_money_request_from')}
              <br />
              <span>
                {request?.requester}
                {t('from')}
              </span>
            </div>
            <div className={styles.reqDetails}>
              <strong>
                {currencySigns[request?.currency]} {request?.amount}
              </strong>
              <div className="description">{request?.description}</div>
            </div>
            <Button className="full-width" onClick={handlePay}>
              {t('pay')}
            </Button>
          </div>
        </div>

        <div className={styles.poweredBy}>
          <div>
            <div>{t('powered_by')}</div>
            <img src={logo} className={styles.logo} alt="logo" />
          </div>
        </div>
        <div className={styles.warning}>{t('please_note_that_you_make_transfer')}</div>
      </div>
      <Footer />
    </div>
  );
};

export default InfoPage;
