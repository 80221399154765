import React from 'react';
import styles from './contacts.module.scss';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
  const { t } = useTranslation();

  const links = [
    { name: 'phone', href: 'tel:+97239444407' },
    { name: 'whatsapp', href: 'http://wa.me/+972522292080' },
  ];
  return (
    <div className={styles.contacts}>
      <h2>{t('contact_us')}</h2>
      <ul>
        {links.map((linkProps) => (
          <li key={linkProps.name}>
            <a {...linkProps} className={styles[linkProps.name]} target="_blank" rel="noreferrer">
              <img
                src={require(`../../assets/${linkProps.name}.svg`)}
                width="16"
                height="16"
                alt={linkProps.name}
              />
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Contacts;
