import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './not-found.module.scss';
import Footer from '../../components/footer';
import exclamation from '../../assets/exclamation.svg';
import { useIntercom } from 'react-use-intercom';
import Contacts from '../../components/contacts';
import Header from '../../components/header';

const NotFound = () => {
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const intercomSettings = {
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  return (
    <div className={styles.errorPage}>
      <Header></Header>

      <div className={styles.content}>
        <img src={exclamation} className={styles.sign} alt="Warning" />
        <strong>{t('page_not_found')}</strong>

        <Contacts></Contacts>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
