import axios from 'axios';

export enum ReqStatuses {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  INACTIVE = 'INACTIVE',
  PROCESSING = 'PROCESSING',
}

export interface ReqInfo {
  name: string;
  amount: string;
  currency: string;
  appPlatform: string;
  appVersion: string;
  description: string;
  encodedId: string;
  ipAddresses: string;
  loggedInUserId: string;
  message: string;
  organizationId: string;
  requestOrigin: string;
  requester: string;
  status: string;
  statusCode: number;
  requestMoneyStatus: ReqStatuses;
}

export const getReqInfo = (encodedId: string): Promise<ReqInfo> => {
  return axios({
    method: 'post',
    data: { encodedId },
    url: `${process.env.REACT_APP_API_URL}/unAuth/requestMoney/info`,
  }).then(({ data }) => data);
};

export const getPaymentUrl = (data: {
  organizationId: string;
  requestMoneyEncodedId: string;
}): Promise<{ pageUrl: string; status: string; message: string }> => {
  return axios({
    method: 'post',
    data,
    url: `${process.env.REACT_APP_API_URL}/unAuth/payment/getPageUrl`,
  }).then(({ data }) => data);
};

export const completeReq = (data: {
  encodedId: string;
  requestPaymentStatus: string;
  transactionIdExternal: string;
}): Promise<{ status: string; message: string }> => {
  return axios({
    method: 'post',
    data,
    url: `${process.env.REACT_APP_API_URL}/unAuth/requestMoney/statusUpdate`,
  }).then(({ data }) => data);
};

export const getDocPdf = (docId: string): Promise<File> => {
  console.log(docId);
  return axios({
    method: 'get',
    responseType: 'arraybuffer',
    url: `${process.env.REACT_APP_API_URL}/unAuth/requestMoney/receipt/${docId}`,
  }).then(({ data }) => new File([data], 'receipt'));
};
