import React, { FC } from 'react';
import styles from './spinner.module.scss';

const Spinner: FC<{ fullPage?: boolean; size?: number }> = ({ fullPage, size = 30 }) => {
  return (
    <div
      className={`${styles.spinnerContainer}  ${fullPage ? styles.fullPage : ''}`}
      style={{ width: size, height: size }}
    >
      <div className={styles.spinner} style={{ width: size, height: size }}>
        <span className="material-symbols-outlined" style={{ fontSize: size }}>
          cached
        </span>
      </div>
    </div>
  );
};

export default Spinner;
