import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './expired.module.scss';
import Footer from '../../components/footer';
import exclamation from '../../assets/exclamation.svg';
import { useIntercom } from 'react-use-intercom';
import Contacts from '../../components/contacts';
import Header from '../../components/header';

const ExpiredPage = () => {
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const intercomSettings = {
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  return (
    <div className={styles.errorPage}>
      <Header></Header>

      <div className={styles.content}>
        <img src={exclamation} className={styles.sign} alt="Warning" />
        <strong>{t('the_link_is_expired')}</strong>
        <p>{t('please_contact_the_recipient_ a_new_link')}</p>

        <Contacts></Contacts>
      </div>
      <Footer />
    </div>
  );
};

export default ExpiredPage;
