import React, { useEffect } from 'react';
import styles from './payment.module.scss';

const Payment = ({ url, handlePayment, handleError }: any) => {
  const handleMessage = ({ data, origin }) => {
    console.log(data, origin);
    if (origin && origin.includes('stbagent')) {
      const url = new URL(data);
      const transactionIdExternal = url.searchParams.get('Id');
      const error = url.searchParams.get('error');

      window.removeEventListener('message', handleMessage);

      if (transactionIdExternal) {
        handlePayment({ transactionIdExternal });
      } else {
        handleError({ error });
      }
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
  });

  return (
    <div className={styles.payment}>
      <iframe src={url} title="Payment page"></iframe>
    </div>
  );
};

export default Payment;
