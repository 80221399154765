import React from 'react';
import styles from './footer.module.scss';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <div className="copyright">
        {t('all_rights_reserved')} | {t('license')}
      </div>
      <a href="http://stbunion.com">stbunion.com</a>
    </div>
  );
};

export default Footer;
