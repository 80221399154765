import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './failed.module.scss';
import Footer from '../../components/footer';
import exclamation from '../../assets/exclamation.svg';
import Button from '../../components/button';
import Contacts from '../../components/contacts';
import { useIntercom } from 'react-use-intercom';
import Header from '../../components/header';

const PaymentError = ({ retry }) => {
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const intercomSettings = {
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  return (
    <div className={styles.errorPage}>
      <Header></Header>

      <div className={styles.content}>
        <img src={exclamation} className={styles.sign} alt="Warning" />
        <strong>{t('the_request_wasnt_paid')}</strong>
        <p>{t('please_try_again')}</p>
        <Button onClick={retry}>{t('pay')}</Button>

        <Contacts></Contacts>
      </div>
      <Footer />
    </div>
  );
};

export default PaymentError;
