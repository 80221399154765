import React from 'react';
import Dropdown from '../dropdown';
import { useTranslation } from 'react-i18next';

import styles from './header.module.scss';

const Header = () => {
  const { i18n } = useTranslation();
  const options = [
    { label: 'EN', value: 'en', icon: 'gb' },
    { label: 'HE', value: 'il', icon: 'il' },
    { label: 'RU', value: 'ru', icon: 'ru' },
  ];
  const defaultValue = options.find(({ value }) => value === i18n.language.split('-')[0]);

  const onChangeLanguage = (data: any) => {
    i18n.changeLanguage(data.value.toLowerCase());
  };

  return (
    <header className={styles.header}>
      <Dropdown
        options={options}
        onChange={onChangeLanguage}
        defaultValue={defaultValue}
        placeholder="Select an option"
      />
    </header>
  );
};

export default Header;
