import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './processing.module.scss';
import Footer from '../../components/footer';
import { useIntercom } from 'react-use-intercom';
import Header from '../../components/header';
import Spinner from '../../components/spinner';

const ProcessingPage = () => {
  const { t } = useTranslation();
  const { boot } = useIntercom();
  const intercomSettings = {
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  return (
    <div className={styles.processingPage}>
      <Header></Header>

      <div className={styles.content}>
        <strong>{t('the_payment_is_being_processed')}</strong>

        <Spinner size={64}></Spinner>
      </div>
      <Footer />
    </div>
  );
};

export default ProcessingPage;
