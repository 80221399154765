import React, { useEffect } from 'react';
import styles from './success.module.scss';
import { useTranslation } from 'react-i18next';
// import Button from '../../components/button';
// import pdf from '../../assets/download-pdf.svg';
import asterisk from '../../assets/asterisk.svg';
import Footer from '../../components/footer';
import { useIntercom } from 'react-use-intercom';
import Header from '../../components/header';

const SuccessPayment = ({ handleDownload }) => {
  const { t } = useTranslation();

  const { boot } = useIntercom();
  const intercomSettings = {
    hideDefaultLauncher: false,
    company: {
      companyId: '4',
      name: 'WEBSITE',
    },
  };

  useEffect(() => {
    boot(intercomSettings);
  }, [boot]);

  return (
    <div className={styles.success}>
      <Header></Header>

      <div className={styles.content}>
        <img src={asterisk} className={styles.sign} alt="Success" />
        <strong>{t('thank_you')}</strong>
        <p>{t('the_funds_have_been_deposited_you_can_now_close_this_page')}</p>
        {/* <Button className="secondary" onClick={() => handleDownload()}>
          <img src={pdf} className={styles.iconPdf} alt="Success" />
          {t('save_receipt')}
        </Button> */}
      </div>
      <Footer />
    </div>
  );
};

export default SuccessPayment;
